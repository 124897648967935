var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c("v-card-title", [
                    !_vm.$store.state.autoEstimate.vehicle.missingCost
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    dark: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.back($event)
                                                    },
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" mdi-arrow-left ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2556139296
                                ),
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.$t("Back")))])]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "h5",
                      {
                        staticClass: "my-0 ml-2",
                        staticStyle: { "font-family": "Rubik !important" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("AddMissingService")) + " ")]
                    ),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "newServiceForm",
                          attrs: { "lazy-validation": "" },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "mr-0 pr-0" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.categories,
                                      "item-text": "description",
                                      "item-value": "id",
                                      placeholder: _vm.$t("Category"),
                                      label: _vm.$t("Category"),
                                      rules: [
                                        function (c) {
                                          return (
                                            !!(c !== 0) ||
                                            _vm.$t("CategoryRequired")
                                          )
                                        },
                                      ],
                                      outlined: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.loadServices()
                                      },
                                    },
                                    model: {
                                      value: _vm.newService.categoryId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newService,
                                          "categoryId",
                                          $$v
                                        )
                                      },
                                      expression: "newService.categoryId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "ml-0 pl-0" },
                                [
                                  _c("v-combobox", {
                                    ref: "serviceNameComboBox",
                                    attrs: {
                                      disabled: _vm.newService.categoryId === 0,
                                      items: _vm.services,
                                      "item-text": "description",
                                      label: _vm.$t("ServiceName"),
                                      placeholder: _vm.$t("ServiceName"),
                                      loading: _vm.loading.services,
                                      "return-object": false,
                                      outlined: "",
                                      "validate-on-blur": true,
                                      rules: [
                                        function (n) {
                                          return (
                                            (!!n && n.trim().length > 0) ||
                                            _vm.$t("ServiceNameRequired")
                                          )
                                        },
                                      ],
                                    },
                                    model: {
                                      value: _vm.newService.serviceName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newService,
                                          "serviceName",
                                          $$v
                                        )
                                      },
                                      expression: "newService.serviceName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            optional: "",
                            "background-color": "teal lighten-5",
                          },
                          model: {
                            value: _vm.selectedTab,
                            callback: function ($$v) {
                              _vm.selectedTab = $$v
                            },
                            expression: "selectedTab",
                          },
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              attrs: {
                                disabled:
                                  _vm.newService.categoryId === 0 ||
                                  _vm.newService.serviceName === "",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "", left: "" } }, [
                                _vm._v("mdi-car-battery"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("Parts")) + " "),
                            ],
                            1
                          ),
                          _c(
                            "v-tab",
                            {
                              attrs: {
                                disabled:
                                  _vm.newService.categoryId === 0 ||
                                  _vm.newService.serviceName === "",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "", left: "" } }, [
                                _vm._v("mdi-wrench"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("Labors")) + " "),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c("find-parts", {
                                attrs: {
                                  categoryId: _vm.newService.categoryId,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0" },
                                [
                                  _c("find-labors", {
                                    on: { laborSelected: _vm.laborSelected },
                                  }),
                                ],
                                1
                              ),
                              _c("v-divider", {
                                staticClass: "mt-4 mb-8 mx-1",
                              }),
                              _c(
                                "v-layout",
                                {
                                  staticClass: "d-flex justify-end",
                                  attrs: { row: "" },
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "d-flex justify-end",
                                      attrs: { xs4: "" },
                                    },
                                    [
                                      _c(
                                        "v-subheader",
                                        {
                                          staticClass: "v-label rr_label mt-2",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("AddFixRR")))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "" } },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          model: {
                                            value: _vm.validLaborAmount,
                                            callback: function ($$v) {
                                              _vm.validLaborAmount = $$v
                                            },
                                            expression: "validLaborAmount",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mr-4",
                                            attrs: {
                                              reverse: "",
                                              label: _vm.$t("Amount"),
                                              type: "number",
                                              suffix: "$",
                                              rules: [
                                                function (c) {
                                                  return (
                                                    (typeof c === "number" &&
                                                      c > 0 &&
                                                      c < 10000) ||
                                                    _vm.$t("PositiveNumber")
                                                  )
                                                },
                                              ],
                                            },
                                            model: {
                                              value: _vm.laborAmount,
                                              callback: function ($$v) {
                                                _vm.laborAmount = _vm._n($$v)
                                              },
                                              expression: "laborAmount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-2 text-none",
                                          staticStyle: {
                                            height: "40px",
                                            width: "100px",
                                          },
                                          attrs: {
                                            elevation: "0",
                                            color: "primary",
                                            disabled: !_vm.validLaborAmount,
                                          },
                                          on: { click: _vm.AddRRLabor },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("Add")) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.$store.state.autoEstimate.services.length > 0
                        ? _c("v-divider", { staticClass: "ma-0 mt-8 pa-0" })
                        : _vm._e(),
                      _vm.$store.state.autoEstimate.services.length > 0
                        ? _c(
                            "v-row",
                            { staticClass: "mt-0 pt-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-flex justify-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2 mt-0 ml-1 text-none",
                                      attrs: {
                                        elevation: "0",
                                        color: "primary",
                                      },
                                      on: { click: _vm.createNewEstimate },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("CreateService")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.confirmDialog.visible,
            callback: function ($$v) {
              _vm.$set(_vm.confirmDialog, "visible", $$v)
            },
            expression: "confirmDialog.visible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" " + _vm._s(_vm.$t("Confirm")) + " "),
              ]),
              _c(
                "v-card-text",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticStyle: { flex: "0" } },
                        [
                          _c("v-icon", { attrs: { color: "green" } }, [
                            _vm._v(" mdi-48px mdi-help-circle "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticStyle: { flex: "1", "padding-top": "25px" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(this.confirmDialog.confirmDialogText) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.confirmDialog.visible = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        outlined: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.processCallbacksFunction()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Ok")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }