var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", height: "348", width: "531" },
          model: {
            value: _vm.serviceAddedDialog,
            callback: function ($$v) {
              _vm.serviceAddedDialog = $$v
            },
            expression: "serviceAddedDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "py-10", attrs: { height: "348" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pt-4 pb-1" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              width: "44",
                              src: require("@/assets/icons/success-icon.png"),
                              "aspect-ratio": "1",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "p",
                          { staticClass: "black--text text-center pb-0 label" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("ServiceHasBeenAdded")) + " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-12 px-9" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-4 text-none",
                              attrs: {
                                block: "",
                                outlined: "",
                                height: "48",
                                color: "#12945F",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addMoreServices()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("AddMoreServices")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text text-none mt-4",
                              attrs: {
                                elevation: "0",
                                block: "",
                                height: "48",
                                color: "#12945F",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.nextStep()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("GoNextStep")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }