var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h3",
        {
          staticClass: "d-block",
          staticStyle: { "font-family": "Rubik !important" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              if ($event.target !== $event.currentTarget) {
                return null
              }
            },
          },
        },
        [
          _c(
            "v-icon",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.closeNotificationDialog.apply(null, arguments)
                },
              },
            },
            [_vm._v("mdi-arrow-left")]
          ),
          _vm._v(" " + _vm._s(_vm.service.description) + " "),
        ],
        1
      ),
      _c(
        "v-alert",
        { attrs: { color: "info", outlined: "", text: "" } },
        [
          _vm._v(" " + _vm._s(_vm.$t("EstimateAmountIs")) + " "),
          _c("b", [
            _vm._v(
              _vm._s(_vm._f("currency")(_vm.service.cost.totalPromCost || 0))
            ),
          ]),
          _vm._v(". " + _vm._s(_vm.$t("FinalAmountDeterminationAlert")) + " "),
          _c(
            "v-icon",
            {
              staticStyle: { "font-size": "1rem" },
              attrs: { title: _vm.$t("TapToSeeInfo"), color: "info" },
              on: {
                click: function ($event) {
                  _vm.serviceHelpDialog = true
                },
              },
            },
            [_vm._v(" mdi-help-circle ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c("v-textarea", {
            attrs: {
              rows: this.$vuetify.breakpoint.smAndDown ? 3 : 5,
              outlined: "",
              "hide-details": "",
              name: "input-7-4",
              placeholder: _vm.$t("AreThereAnyOtherDetail"),
            },
            model: {
              value: _vm.serviceComment,
              callback: function ($$v) {
                _vm.serviceComment = $$v
              },
              expression: "serviceComment",
            },
          }),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "justify-end" },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-none",
              attrs: { elevation: "0", color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.addService()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("AddService")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.serviceHelpDialog,
            callback: function ($$v) {
              _vm.serviceHelpDialog = $$v
            },
            expression: "serviceHelpDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "px-3 py-3 justify-space-between" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-bold mb-0",
                      staticStyle: { "font-size": "1rem" },
                    },
                    [_vm._v(_vm._s(_vm.$t("Information")))]
                  ),
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "black" },
                      on: {
                        click: function ($event) {
                          _vm.serviceHelpDialog = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pt-4 pb-1 px-3" }, [
                _c(
                  "p",
                  {
                    staticClass: "black--text",
                    staticStyle: { "font-size": "1rem" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("IncorrectServiceSelectedAlert"))
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("service-added-component", {
        attrs: { "service-added-dialog": _vm.serviceAddedDialog },
        on: {
          "next-step": _vm.nextStep,
          "add-more-services": _vm.closeNotificationDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }