var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-window",
    {
      staticStyle: { overflow: "auto !important" },
      model: {
        value: _vm.windowsStep,
        callback: function ($$v) {
          _vm.windowsStep = $$v
        },
        expression: "windowsStep",
      },
    },
    [
      _c(
        "v-window-item",
        { attrs: { value: 0 } },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-0" },
            [
              _c(
                "v-row",
                {
                  staticClass: "mt-2",
                  staticStyle: { position: "relative", background: "white" },
                },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          "prepend-inner-icon": "mdi-magnify",
                          placeholder: _vm.$t("Search"),
                          label: _vm.$t("FindParts"),
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                          clearable: "",
                          disabled: _vm.loadingIndicator,
                        },
                        on: { keyup: _vm.searchfn, "click:clear": _vm.clearfn },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-expand-transition",
            [
              _c(
                "v-card-text",
                { staticClass: "pt-0 v-card_text_scroll" },
                [
                  _vm.notmatching && !_vm.loadingIndicator
                    ? _c(
                        "v-row",
                        {
                          staticClass: "mt-2",
                          staticStyle: {
                            position: "relative",
                            background: "white",
                          },
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-alert",
                                { attrs: { type: "warning", shaped: "" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("NoPartFoundByTerm")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.loadingIndicator
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-center mt-10" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 70,
                                  width: 5,
                                  color: "#12945f",
                                  indeterminate: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.loadingIndicator
                    ? _c(
                        "v-list",
                        {
                          staticClass: "pt-0",
                          staticStyle: { "max-height": "250px" },
                          attrs: { "two-line": "" },
                        },
                        [
                          _c(
                            "v-list-item-group",
                            {
                              attrs: {
                                mandatory: "",
                                "active-class": "pink--text",
                              },
                            },
                            [
                              _vm._l(_vm.parts, function (part, index) {
                                return [
                                  _c("v-list-item", {
                                    key: "missing-part-" + index,
                                    staticStyle: { width: "100%" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.displayEstimatedCostForSelectedPart(
                                          part
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var active = ref.active
                                            return [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", {
                                                    staticClass: "wrap-text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        part.description
                                                      ),
                                                    },
                                                  }),
                                                  _c("v-list-item-subtitle", {
                                                    staticClass: "wrap-text",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        part.techNotes
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _c(
                                                    "v-list-item-action-text",
                                                    {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          "$ " +
                                                            part.invoicePrice.toFixed(
                                                              2
                                                            ) +
                                                            " + $ " +
                                                            part.corePrice.toFixed(
                                                              2
                                                            )
                                                        ),
                                                      },
                                                    }
                                                  ),
                                                  active
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "yellow darken-3",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-account-clock "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  index < _vm.parts.length - 1
                                    ? _c("v-divider", {
                                        key: "missing-part-divider-" + index,
                                      })
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-window-item",
        { attrs: { value: 1 } },
        [
          _c("details-service-added", {
            attrs: { service: _vm.partSelected },
            on: {
              "next-step": function ($event) {
                return _vm.nextStep()
              },
              "windows-step": function ($event) {
                _vm.windowsStep = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }