<template>
  <v-dialog v-model="showDialog" width="831" max-width="90%" persistent class="p-4">
    <v-card class="p-5">
      <v-card-title class="pt-5" style="justify-content: space-between">
        <v-row>
          <v-col cols="11">
            <span class="titleC mt-0 mb-0" style="word-break: break-word;">{{this.$t('DontSeeTheServicesYouNeed')}}</span>
          </v-col>
          <v-col cols="1">
            <i class="mdi mdi-close" style="float: right;padding: 0px 10px" @click="cancelDialog()"></i>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-alert color="info" outlined text>
          {{this.$t('infoMissingService')}}.
          <v-icon
            style="font-size: 1rem"
            @click="displayServiceHelpDialog()"
            :title="$t('TapToSeeInfo')"
            color="info">
            mdi-help-circle
          </v-icon>
        </v-alert>
        <div style="margin-bottom:20px">
          <v-textarea
            v-model="comment"
            :placeholder="this.$t('DescribeService')"
            rows="5"
            outlined
            hide-details
            no-resize
            name="input-7-4">
          </v-textarea>
        </div>
        <v-card-actions style="justify-content: right">
          <v-btn
            elevation="0"
            style="padding: 0px 40px"
            :disabled="comment===''"
            color="primary"
          @click="hideDialog()">Save</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="serviceHelpDialog"
      width="500">
      <v-card>
        <v-card-title class="px-3 py-3 justify-space-between">
          <p class="text-bold mb-0" style="font-size: 1rem">{{$t('Information')}}</p>
          <v-icon @click="serviceHelpDialog =false" color="black">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 pb-1 px-3">
          <p class="black--text" style="font-size: 1rem"> {{$t('IncorrectServiceSelectedAlert')}}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import Service from '@/store/model/Service';

export default {
  name: 'NewService',
  props: ['showDialog'],
  data() {
    return {
      comment: '',
      serviceHelpDialog: false,
      service: new Service(),
      dataStorage: this.$store.state.autoEstimate,
    };
  },
  methods: {
    hideDialog() {
      this.$emit('hideNewServiceDialog', this.comment);
    },
    cancelDialog() {
      this.comment = '';
      this.$emit('hideNewServiceDialog', '');
    },
    displayServiceHelpDialog() {
      this.serviceHelpDialog = true;
    },
  },
};
</script>

<style scoped>
  .inputC {
    height: 50%;
  }
  .dialogC {
    background: #FFFFFF;
  }
  .titleC {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    padding-left: 3px;
    /* or 23px */
    color: #000000;
  }
</style>
