var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        { staticClass: "pt-0 pl-0 pr-0" },
        [
          _c(
            "v-row",
            { staticClass: "mt-4", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c("v-text-field", {
                    staticClass: "mr-1",
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      label: _vm.$t("SearchParts"),
                      placeholder: _vm.$t("SearchParts"),
                      "hide-details": "",
                      outlined: "",
                      dense: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 mt-0 mb-0 ml-0 text-none",
                      staticStyle: { width: "105px", height: "40px" },
                      attrs: {
                        elevation: "0",
                        loading: _vm.loading.search,
                        disabled: _vm.search.length < 4,
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.SearchPart()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Search")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-breadcrumbs", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.breadcrumbsItems.length > 1,
                expression: "breadcrumbsItems.length > 1",
              },
            ],
            staticStyle: { padding: "18px 2px 0" },
            attrs: { items: _vm.breadcrumbsItems, divider: ">" },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-breadcrumbs-item",
                      {
                        staticClass: "breadcrumb-categories",
                        attrs: { disabled: _vm.loading.parts, link: true },
                        on: {
                          click: function ($event) {
                            return _vm.reloadPartData(item.index)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.text) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "v-row",
            { staticClass: "mt-4", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-2", staticStyle: { "padding-left": "2px" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto",
                      staticStyle: { height: "350px" },
                      attrs: {
                        tile: "",
                        loading: _vm.loading.parts,
                        disabled: _vm.loading.parts,
                        elevation: "0",
                        outlined: "",
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticStyle: {
                            "background-color": "#e0f2f1",
                            padding: "6px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Categories")) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "305px",
                            overflow: "auto !important",
                          },
                        },
                        [
                          _c(
                            "v-list",
                            { staticStyle: { padding: "0" } },
                            [
                              _c(
                                "v-list-item-group",
                                [
                                  _vm._l(
                                    _vm.categories,
                                    function (category, index) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          {
                                            key: "content-" + index,
                                            on: {
                                              click: function ($event) {
                                                return _vm.navigateByCategory(
                                                  category
                                                )
                                              },
                                            },
                                          },
                                          [
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        category.text
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                        index < _vm.categories.length - 1
                                          ? _c("v-divider", {
                                              key: "divider-" + index,
                                              staticStyle: { margin: "0" },
                                            })
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto",
                      staticStyle: { height: "350px" },
                      attrs: {
                        loading: _vm.loading.parts,
                        disabled: _vm.loading.parts,
                        elevation: "0",
                        outlined: "",
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticStyle: {
                            "background-color": "#e0f2f1",
                            padding: "6px",
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("Parts")) + " "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "green" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                color: "#12945F",
                                                fab: "",
                                                dark: "",
                                                small: "",
                                                absolute: "",
                                                center: "",
                                                right: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showSelectStore = true
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-warehouse"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("ChangeStore"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "305px",
                            overflow: "auto !important",
                          },
                        },
                        [
                          _c(
                            "v-list",
                            {
                              staticStyle: { padding: "0" },
                              attrs: { "two-line": "", "return-object": "" },
                            },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: {
                                    mandatory: "",
                                    "active-class": "pink--text",
                                  },
                                  model: {
                                    value: _vm.selectedPart,
                                    callback: function ($$v) {
                                      _vm.selectedPart = $$v
                                    },
                                    expression: "selectedPart",
                                  },
                                },
                                [
                                  _vm._l(_vm.parts, function (part, index) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        {
                                          key: "content-part-" + index,
                                          attrs: { value: part },
                                        },
                                        [
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      part.description +
                                                        " (" +
                                                        part.manufacturerName +
                                                        ")"
                                                    ),
                                                  },
                                                }),
                                                _c("v-list-item-subtitle", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "$ " +
                                                        part.invoicePrice.toFixed(
                                                          2
                                                        )
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-icon",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  margin: "0",
                                                  "align-self": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "#12945F",
                                                      title: _vm.$t("Details"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.displayPartDetails(
                                                          part
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" mdi-information ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                        ],
                                        2
                                      ),
                                      index < _vm.parts.length - 1
                                        ? _c("v-divider", {
                                            key: "divider-part-" + index,
                                            staticStyle: { margin: "0" },
                                          })
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "d-flex justify-end", attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs2: "" } },
                [
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.partInfo.validQty,
                        callback: function ($$v) {
                          _vm.$set(_vm.partInfo, "validQty", $$v)
                        },
                        expression: "partInfo.validQty",
                      },
                    },
                    [
                      _c("v-text-field", {
                        ref: "input",
                        staticClass: "centered-input mr-2",
                        attrs: {
                          type: "number",
                          label: _vm.$t("Qty"),
                          step: "any",
                          min: "1",
                          rules: [
                            function (c) {
                              return (
                                (typeof c === "number" && c > 0) ||
                                _vm.$t("PositiveNumber")
                              )
                            },
                          ],
                        },
                        model: {
                          value: _vm.partInfo.qty,
                          callback: function ($$v) {
                            _vm.$set(_vm.partInfo, "qty", _vm._n($$v))
                          },
                          expression: "partInfo.qty",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs2: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-2 mt-3 text-none",
                      staticStyle: { height: "40px", width: "105px" },
                      attrs: {
                        elevation: "0",
                        loading: _vm.loading.addingPart,
                        disabled:
                          _vm.selectedPart.partNumber === "" ||
                          !_vm.partInfo.validQty,
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.AddPart()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("AddPart")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.showPartDetails,
            callback: function ($$v) {
              _vm.showPartDetails = $$v
            },
            expression: "showPartDetails",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
                _vm._v(
                  " " + _vm._s(this.selectedDetailsPart.description) + " "
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-3", attrs: { cols: "5" } },
                        [
                          _c("v-img", {
                            staticClass: "grey darken-4",
                            attrs: {
                              src: this.selectedDetailsPart.partImage,
                              height: "170",
                              width: "170",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-7 pl-3", attrs: { cols: "7" } },
                        [
                          _c("v-row", [
                            _c("p", [
                              _c("strong", [_vm._v("Manufacturer:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.selectedDetailsPart.manufacturerName
                                  )
                              ),
                            ]),
                          ]),
                          _c("v-row", [
                            _c("p", [
                              _c("strong", [_vm._v("Price:")]),
                              _vm._v(
                                " $ " +
                                  _vm._s(
                                    this.selectedDetailsPart.invoicePrice.toFixed(
                                      2
                                    )
                                  )
                              ),
                            ]),
                          ]),
                          _c("v-row", [
                            _c("p", [
                              _c("strong", [_vm._v("Notes:")]),
                              _vm._v(
                                " " + _vm._s(this.selectedDetailsPart.techNotes)
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showPartDetails = false
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-z-store", { attrs: { showSelectStore: _vm.showSelectStore } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }