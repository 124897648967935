var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadingIndicator
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-center mt-10" },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      size: 70,
                      width: 5,
                      color: "#12945f",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.windowsStep === 0 && !_vm.loadingIndicator
        ? _c(
            "v-row",
            {
              staticStyle: {
                position: "relative",
                background: "white",
                width: "100%",
              },
            },
            [
              _c(
                "v-col",
                { staticClass: "pr-0" },
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      placeholder: _vm.$t("SearchForYourService"),
                      label: _vm.$t("Search"),
                      "hide-details": "",
                      outlined: "",
                      dense: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loadingIndicator
        ? _c(
            "v-window",
            {
              staticStyle: { overflow: "auto !important" },
              model: {
                value: _vm.windowsStep,
                callback: function ($$v) {
                  _vm.windowsStep = $$v
                },
                expression: "windowsStep",
              },
            },
            [
              _c(
                "v-window-item",
                { attrs: { value: 0 } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0" },
                    [
                      _c("v-treeview", {
                        ref: "serviceTree",
                        attrs: {
                          active: _vm.active,
                          items: _vm.categories,
                          search: _vm.toSearch,
                          filter: _vm.filter,
                          activatable: "",
                          "open-on-click": "",
                          transition: "",
                        },
                        on: {
                          "update:active": [
                            function ($event) {
                              _vm.active = $event
                            },
                            function ($event) {
                              return _vm.nodeClick()
                            },
                          ],
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  !item.children && item.isSelected
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "green" } },
                                        [_vm._v(" mdi-check-circle ")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          743758045
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-window-item",
                { attrs: { value: 1 } },
                [
                  _c("details-service-added", {
                    attrs: { service: _vm.serviceSelected },
                    on: {
                      "next-step": function ($event) {
                        return _vm.nextStep()
                      },
                      "windows-step": function ($event) {
                        _vm.windowsStep = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }