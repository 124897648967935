var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "col-12 col-md-8" },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("step-title-header", {
                        attrs: {
                          title: _vm.$t("Service"),
                          currentStep: "2",
                          subtitle: _vm.$store.state.autoEstimate
                            .isContactCenter
                            ? _vm.$t("SelectServiceAndNew")
                            : _vm.$t("SelectService"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _vm.$store.state.autoEstimate.isContactCenter &&
                      !_vm.showMissingService
                        ? _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c(
                                  "button",
                                  {
                                    staticStyle: { color: "green" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showMissingService = true
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "green" } },
                                      [_vm._v(" mdi-plus-circle ")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("AddMissingService")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("services-by-category", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.showMissingService,
                                    expression: "!showMissingService",
                                  },
                                ],
                                on: {
                                  "next-step": function ($event) {
                                    return _vm.nextStep()
                                  },
                                },
                              }),
                              _c("add-missing-service", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showMissingService,
                                    expression: "showMissingService",
                                  },
                                ],
                                on: {
                                  "next-step": function ($event) {
                                    return _vm.nextStep()
                                  },
                                  "missing-service": function ($event) {
                                    _vm.showMissingService = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-0 pt-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2 mt-5 text-none",
                                  attrs: { elevation: "0" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backStep()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Back")) + " ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2 mt-5 ml-1 text-none",
                                  attrs: {
                                    elevation: "0",
                                    color: "primary",
                                    disabled:
                                      this.dataStorage.services.length === 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.nextStep()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Next")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "col-12 col-md-4" },
            [_c("cart-component")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }