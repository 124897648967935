var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-window",
    {
      staticStyle: { overflow: "auto !important" },
      model: {
        value: _vm.windowsStep,
        callback: function ($$v) {
          _vm.windowsStep = $$v
        },
        expression: "windowsStep",
      },
    },
    [
      _c(
        "v-window-item",
        { attrs: { value: 0 } },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-0" },
            [
              _c("find-labors", {
                on: {
                  laborSelected: _vm.displayEstimatedCostForSelectedService,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-window-item",
        { attrs: { value: 1 } },
        [
          _c("details-service-added", {
            attrs: { service: _vm.serviceSelected },
            on: {
              "next-step": function ($event) {
                return _vm.nextStep()
              },
              "windows-step": function ($event) {
                _vm.windowsStep = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }