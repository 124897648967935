var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "col-12 col-md-8" },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("step-title-header", {
                        attrs: {
                          title: _vm.$t("Service"),
                          subtitle: _vm.$t("SelectOilType"),
                          currentStep: "2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-combobox", {
                        attrs: {
                          items: _vm.oilTypes,
                          label: _vm.$t("OilType"),
                          outlined: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.oilTypeSelected()
                          },
                        },
                        model: {
                          value: _vm.oilType,
                          callback: function ($$v) {
                            _vm.oilType = $$v
                          },
                          expression: "oilType",
                        },
                      }),
                      _vm.oilType !== ""
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: { border: "1px solid #ccc" },
                                      attrs: { elevation: "0" },
                                    },
                                    [
                                      _c("v-card-title", [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "CarRepairAndMaintainService.OtherServices.Services.OilChange"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-text",
                                        [
                                          _vm.dataStorage.stepSettings
                                            .loadingIndicator
                                            ? _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-center mt-10",
                                                    },
                                                    [
                                                      _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            size: 70,
                                                            width: 5,
                                                            color: "#12945f",
                                                            indeterminate: "",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            [
                                              !_vm.dataStorage.stepSettings
                                                .loadingIndicator &&
                                              !_vm.dataStorage.stepSettings
                                                .couponFree
                                                ? _c(
                                                    "v-alert",
                                                    {
                                                      attrs: {
                                                        color: "info",
                                                        outlined: "",
                                                        text: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "EstimateAmountIs"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              this.oilChangeCost
                                                                .totalPromCost ||
                                                                0
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Includes50Point"
                                                            )
                                                          ) +
                                                          ". "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              this.$store.state.autoEstimate
                                                .stepSettings.couponFree
                                                ? _c(
                                                    "v-alert",
                                                    {
                                                      attrs: {
                                                        color: "info",
                                                        outlined: "",
                                                        text: "",
                                                      },
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Congratulation"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      this.$store.state
                                                        .autoEstimate
                                                        .stepSettings
                                                        .isRegularOilChange
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "YouHaveReceivedAFreeOilChange"
                                                                )
                                                              ) + "."
                                                            ),
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "YouHaveReceivedOilChange"
                                                                )
                                                              ) +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "currency"
                                                                  )(
                                                                    this
                                                                      .oilChangeCost
                                                                      .totalPromCost
                                                                  )
                                                                ) +
                                                                "."
                                                            ),
                                                          ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "ClickOnNext"
                                                              )
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "20px",
                                                  },
                                                },
                                                [
                                                  _c("v-textarea", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "",
                                                      name: "input-7-4",
                                                      placeholder: _vm.$t(
                                                        "AreThereAnyOtherDetail"
                                                      ),
                                                    },
                                                    model: {
                                                      value: _vm.serviceComment,
                                                      callback: function ($$v) {
                                                        _vm.serviceComment = $$v
                                                      },
                                                      expression:
                                                        "serviceComment",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-0 pt-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2 mt-5 text-none",
                                  attrs: { elevation: "0" },
                                  on: { click: _vm.backStep },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Back")) + " ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2 mt-5 ml-1 text-none",
                                  attrs: {
                                    elevation: "0",
                                    color: "primary",
                                    disabled: _vm.oilType == "",
                                  },
                                  on: { click: _vm.nextStep },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Next")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "col-12 col-md-4" },
            [_c("cart-component")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }