var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "col-12 col-md-8", attrs: { md: "8", lg: "8" } },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("step-title-header", {
                        attrs: {
                          title: _vm.$t("Location"),
                          subtitle: _vm.$t("EnterLocationOfTheVehicle"),
                          currentStep: "3",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-row", [_c("v-col", [_c("location")], 1)], 1),
                      _c(
                        "v-row",
                        { staticClass: "mt-0 pt-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2 mt-5 text-none",
                                  attrs: { elevation: "0" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backStep()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Back")) + " ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2 mt-5 ml-1 text-none",
                                  attrs: {
                                    elevation: "0",
                                    color: "primary",
                                    disabled:
                                      this.$store.state.autoEstimate.location
                                        .address === "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.nextStep()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Next")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "col-12 col-md-4" },
            [_c("cart-component")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }