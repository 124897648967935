var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 px-sm-3" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { md: "8", lg: "8" } },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("step-title-header", {
                        attrs: {
                          title: _vm.$t("Confirmation"),
                          currentStep: _vm.dataStorage.isContactCenter ? 5 : 6,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mt-0",
                          attrs: { id: "confirmation-content" },
                        },
                        [
                          _vm.dataStorage.vehicle.isValid()
                            ? _c("section", { attrs: { id: "vehicle" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "subheader-container margin-bottom-10",
                                    attrs: { "_ngcontent-sgu-c428": "" },
                                  },
                                  [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "subheader quote-header-4 mb-1",
                                        attrs: { "_ngcontent-sgu-c428": "" },
                                      },
                                      [_vm._v(" " + _vm._s(_vm.$t("Vehicle")))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "margin-bottom-0 mb-0" },
                                  [
                                    _c("span", { staticClass: "cart-gray" }, [
                                      _vm._v(_vm._s(_vm.$t("Vehicle")) + ": "),
                                    ]),
                                    _c("span", { staticClass: "black--text" }, [
                                      _vm._v(
                                        _vm._s(
                                          this.dataStorage.vehicle.toString()
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm.dataStorage.vehicle.transmition != ""
                                  ? _c(
                                      "p",
                                      { staticClass: "margin-bottom-0 mb-0" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "cart-gray" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Transmition")) +
                                                ": "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "black--text" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.dataStorage.vehicle
                                                  .transmition
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.dataStorage.vehicle.driveTrain != ""
                                  ? _c(
                                      "p",
                                      { staticClass: "margin-bottom-0 mb-0" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "cart-gray" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Drivetrain")) +
                                                ": "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "black--text" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.dataStorage.vehicle
                                                  .driveTrain
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _c("v-divider", { staticClass: "mb-2 mt-4" }),
                          _vm.totalCosts().totalLabor < this.minimumServiceFee
                            ? _c(
                                "v-alert",
                                { attrs: { type: "success", dismissible: "" } },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.$t("MinimumServiceFeeTitle")) +
                                        " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            this.minimumServiceFee
                                          )
                                        ) +
                                        ". "
                                    ),
                                  ]),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.$t("MinimumServiceFeeDesc")) +
                                        " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            this.minimumServiceFee
                                          )
                                        ) +
                                        "."
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          this.dataStorage.services.length > 0
                            ? _c(
                                "section",
                                { attrs: { id: "service" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "subheader-container",
                                      attrs: { "_ngcontent-sgu-c428": "" },
                                    },
                                    [
                                      _c(
                                        "h1",
                                        {
                                          staticClass:
                                            "subheader quote-header-4 mb-1",
                                          attrs: { "_ngcontent-sgu-c428": "" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("Service"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    this.$store.state.autoEstimate.services,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "d-flex dark-blue",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "mb-0 cart-gray",
                                              staticStyle: { width: "80%" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.service || "") +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "mb-0 cart-gray",
                                              staticStyle: {
                                                width: "110px",
                                                "text-align": "right",
                                                "padding-top": "0px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      item.estimateCosts
                                                        .totalPromCost
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  this.dataStorage.stepSettings
                                    .couponCodeApplied
                                    ? _c("div", { staticClass: "d-flex" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "cart-gray",
                                            staticStyle: { width: "80%" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Discount")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "cart-gray",
                                            staticStyle: {
                                              width: "110px",
                                              "text-align": "right",
                                              "padding-top": "0",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.totalCosts().discount
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex",
                                      staticStyle: {
                                        color: "rgb(18, 148, 95)",
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "text-left mb-0",
                                          staticStyle: { width: "80%" },
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              "Total " +
                                                _vm._s(
                                                  _vm.totalCosts().totalLabor <
                                                    this.minimumServiceFee
                                                    ? _vm.$t(
                                                        "MinimumServiceFee"
                                                      )
                                                    : ""
                                                )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "mb-0",
                                          staticStyle: {
                                            width: "110px",
                                            "text-align": "right",
                                            "padding-top": "0px",
                                          },
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.totalCosts().total
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _c("v-divider", { staticClass: "mb-2 mt-4" }),
                          _vm.dataStorage.location.isValid()
                            ? _c("section", { attrs: { id: "location" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "subheader-container margin-bottom-10",
                                    attrs: { "_ngcontent-sgu-c428": "" },
                                  },
                                  [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "subheader quote-header-4 mb-1",
                                        attrs: { "_ngcontent-sgu-c428": "" },
                                      },
                                      [_vm._v(" " + _vm._s(_vm.$t("Location")))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "margin-bottom-0 mb-0" },
                                  [
                                    _c("span", { staticClass: "cart-gray" }, [
                                      _vm._v(_vm._s(_vm.$t("Address")) + ": "),
                                    ]),
                                    _c("span", { staticClass: "black--text" }, [
                                      _vm._v(
                                        _vm._s(_vm.dataStorage.location.address)
                                      ),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("v-divider", { staticClass: "mb-2 mt-4" }),
                          _c(
                            "section",
                            { attrs: { id: "appointmentDateTime" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "subheader-container margin-bottom-10",
                                  attrs: { "_ngcontent-sgu-c428": "" },
                                },
                                [
                                  _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "subheader quote-header-4 mb-1",
                                      attrs: { "_ngcontent-sgu-c428": "" },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("Schedule")))]
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                                _c("span", { staticClass: "cart-gray" }, [
                                  _vm._v(_vm._s(_vm.$t("DateTime")) + ": "),
                                ]),
                                _c("span", { staticClass: "black--text" }, [
                                  _vm._v(_vm._s(_vm.getAppointmentDateTime())),
                                ]),
                              ]),
                            ]
                          ),
                          this.totalCosts().total > 0
                            ? _c("v-divider", { staticClass: "mb-2 mt-4" })
                            : _vm._e(),
                          !_vm.dataStorage.isContactCenter &&
                          this.totalCosts().total > 0
                            ? _c("section", { attrs: { id: "payment" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "subheader-container margin-bottom-10",
                                    attrs: { "_ngcontent-sgu-c428": "" },
                                  },
                                  [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "subheader quote-header-4 mb-1",
                                        attrs: { "_ngcontent-sgu-c428": "" },
                                      },
                                      [_vm._v(" " + _vm._s(_vm.$t("Payment")))]
                                    ),
                                  ]
                                ),
                                _vm.dataStorage.payment.isNewProfile
                                  ? _c("div", [
                                      _c(
                                        "p",
                                        { staticClass: "margin-bottom-0 mb-0" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "cart-gray" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("CardName")) +
                                                  ": "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "black--text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.dataStorage.payment
                                                    .newPaymentProfile.CARD_NAME
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "margin-bottom-0 mb-0" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "cart-gray" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "CardNumberWithOutAsterix"
                                                  )
                                                ) + ": "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "black--text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.dataStorage.payment
                                                    .newPaymentProfile
                                                    .CARD_NUMBER
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                !_vm.dataStorage.payment.isNewProfile
                                  ? _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "dark-blue margin-bottom-0 mb-0",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "cart-gray" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "CardNumberWithOutAsterix"
                                                  )
                                                ) + ": "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "black--text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.dataStorage.payment
                                                    .newPaymentProfile
                                                    .CARD_NUMBER
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          this.$store.state.autoEstimate.stepSettings.couponFree
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mt-5",
                                  attrs: {
                                    dismissible: "",
                                    color: "info",
                                    outlined: "",
                                    text: "",
                                  },
                                },
                                [
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.$t("Congratulation"))),
                                  ]),
                                  this.$store.state.autoEstimate.stepSettings
                                    .isRegularOilChange
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "YouHaveReceivedAFreeOilChange"
                                            )
                                          )
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("YouHaveReceivedOilChange")
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.totalCosts().total
                                              )
                                            )
                                        ),
                                      ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-0 pt-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-sm-flex\n     justify-sm-space-between",
                            },
                            [
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2 mt-5  text-capitalize",
                                      on: {
                                        click: function ($event) {
                                          return _vm.backStep()
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("Back")) + " ")]
                                  )
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2 mt-5 ml-1 text-none",
                                  attrs: {
                                    color: "primary",
                                    elevation: "0",
                                    block: _vm.$vuetify.breakpoint.xs,
                                    loading: _vm.submitLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.makeAutoEstimateAppointment()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("SubmitConfirm")) + " "
                                  ),
                                ]
                              ),
                              _vm.$vuetify.breakpoint.xs
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2 mt-5 text-capitalize",
                                      attrs: {
                                        block: _vm.$vuetify.breakpoint.xs,
                                        elevation: "0",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.backStep()
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("Back")) + " ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": 530 },
          model: {
            value: _vm.confirmAppointmentDialog,
            callback: function ($$v) {
              _vm.confirmAppointmentDialog = $$v
            },
            expression: "confirmAppointmentDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "py-10" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              width: "120",
                              src: require("@/assets/icons/success-icon.png"),
                              "aspect-ratio": "1",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "p",
                          {
                            staticClass: "black--text text-center pb-0",
                            attrs: { id: "confirm-dialog-first-line" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("AppointmentConfirmedMessage")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c("v-col", { staticClass: "pt-0 mb-0" }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            staticStyle: { "font-size": "1rem" },
                            attrs: { id: "confirm-dialog-second-line" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("ThankForUsingUcarMobile")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "col-12 px-9" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text text-none",
                              attrs: {
                                block: "",
                                height: "42",
                                color: "#12945F",
                                to: { name: "MyAppointments" },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("ViewAppointment")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-4 text-none",
                              attrs: {
                                block: "",
                                outlined: "",
                                height: "42",
                                color: "#12945F",
                                to: "/",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("GoToHome")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": 530 },
          model: {
            value: _vm.confirmAppointmentCCDialog,
            callback: function ($$v) {
              _vm.confirmAppointmentCCDialog = $$v
            },
            expression: "confirmAppointmentCCDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "py-10" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              width: "64",
                              src: require("@/assets/icons/success-icon.png"),
                              "aspect-ratio": "1",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "p",
                          {
                            staticClass: "black--text text-center pb-0",
                            attrs: { id: "confirm-dialog-first-line-cc" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("AppointmentCCConfirmed.Message1")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c("v-col", { staticClass: "col-12 pt-5 mb-0" }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            staticStyle: { "font-size": "1rem" },
                            attrs: { id: "confirm-dialog-second-line-cc" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("AppointmentCCConfirmed.Message2")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "col-12 pt-0 mb-5 text-center text-decoration-underline",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-dark",
                              attrs: {
                                target: "_blank",
                                rel: "noopener noreferrer",
                              },
                              on: { click: _vm.transferURLToCC },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("AppointmentCCConfirmed.Message3")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "col-12 px-9" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text text-none",
                              attrs: {
                                block: "",
                                height: "42",
                                color: "#12945F",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeConfirmationDialog()
                                },
                              },
                            },
                            [_vm._v(" Close ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": 410 },
          model: {
            value: _vm.confirmAppointmentErrorDialog,
            callback: function ($$v) {
              _vm.confirmAppointmentErrorDialog = $$v
            },
            expression: "confirmAppointmentErrorDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "px-0 py-0" },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mb-0",
                      attrs: { text: "", color: "error" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "mt-0 mb-2",
                              staticStyle: {
                                "font-family": "rubik !important",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.errorMessage.title) + " ")]
                          ),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.confirmAppointmentErrorDialog = false
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { color: "red" } }, [
                                _vm._v(" mdi-close "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.errorMessage.text) + " "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": 410 },
          model: {
            value: _vm.declineErrorDialog,
            callback: function ($$v) {
              _vm.declineErrorDialog = $$v
            },
            expression: "declineErrorDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "px-0 py-0" },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mb-0",
                      attrs: { text: "", color: "error" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "mt-0 mb-2",
                              staticStyle: {
                                "font-family": "rubik !important",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.errorMessage.title) + " ")]
                          ),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.declineErrorDialog = false
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { color: "red" } }, [
                                _vm._v(" mdi-close "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.errorMessage.text) + " "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "red" },
                              on: { click: _vm.goToPaymentStep },
                            },
                            [_vm._v(" Retry ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                color: "red",
                                to: { name: "Home" },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }