var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticClass: "mt-2",
          staticStyle: { position: "relative", background: "white" },
        },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  "prepend-inner-icon": "mdi-magnify",
                  placeholder: _vm.$t("Search"),
                  label: _vm.$t("FindServices"),
                  "hide-details": "",
                  outlined: "",
                  dense: "",
                  clearable: "",
                  disabled: _vm.loadingIndicator,
                },
                on: { keyup: _vm.searchfn, "click:clear": _vm.clearfn },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-card-text",
            {
              staticClass: "pa-0 v-card_text_scroll",
              staticStyle: {
                "padding-left": "3px !important",
                "padding-right": "3px !important",
              },
            },
            [
              _vm.notmatching && !_vm.loadingIndicator
                ? _c(
                    "v-row",
                    {
                      staticClass: "mt-2",
                      staticStyle: {
                        position: "relative",
                        background: "white",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-alert",
                            { attrs: { type: "warning", shaped: "" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("NoServiceFoundByTerm")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.loadingIndicator
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center mt-10" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 70,
                              width: 5,
                              color: "#12945f",
                              indeterminate: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.loadingIndicator
                ? _c(
                    "v-list",
                    {
                      staticStyle: { "max-height": "250px" },
                      attrs: { "two-line": "" },
                    },
                    [
                      _c(
                        "v-list-item-group",
                        {
                          staticClass: "mt-0",
                          attrs: {
                            mandatory: "",
                            "active-class": "pink--text",
                          },
                        },
                        [
                          _vm._l(_vm.services, function (service, index) {
                            return [
                              _c("v-list-item", {
                                key: "" + service.id,
                                staticStyle: { width: "100%" },
                                on: {
                                  click: function ($event) {
                                    return _vm.laborSelected(service)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", {
                                                staticClass: "wrap-text",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    service.laborName
                                                  ),
                                                },
                                              }),
                                              _c("v-list-item-subtitle", {
                                                staticClass: "wrap-text",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    service.notes
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-list-item-action-text", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    service.baseLaborTime +
                                                      " hr ($ " +
                                                      service.amount.toFixed(
                                                        2
                                                      ) +
                                                      ")"
                                                  ),
                                                },
                                              }),
                                              active
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color:
                                                          "yellow darken-3",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-account-clock "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              index < _vm.services.length - 1
                                ? _c("v-divider", {
                                    key: "missing-service-divider-" + index,
                                    staticClass: "mt-0 mb-0",
                                  })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }