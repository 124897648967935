var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subheader-container" }, [
    _c("div", [
      _c("h1", { staticClass: "subheader quote-header-4 mb-0" }, [
        _vm._v(_vm._s(_vm.title) + " "),
        _vm.$vuetify.breakpoint.smAndDown
          ? _c(
              "span",
              {
                staticStyle: {
                  "font-size": "1rem",
                  "margin-left": "3px",
                  "font-weight": "normal",
                },
              },
              [
                _vm._v(
                  " (Step " +
                    _vm._s(_vm.currentStep) +
                    " of " +
                    _vm._s(this.dataStorage.stepSettings.totalSteps) +
                    ") "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c("span", { attrs: { id: "subtitle" } }, [_vm._v(_vm._s(_vm.subtitle))]),
    ]),
    _c("div", { staticClass: "subheader-line" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }