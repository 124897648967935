var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    width: "100%",
                    "justify-content": "start",
                  },
                },
                [
                  _vm.dataStorage.stepSettings.serviceCostStep === 1
                    ? _c(
                        "v-icon",
                        {
                          attrs: { color: "black" },
                          on: {
                            click: function ($event) {
                              return _vm.displayEstimatedCostForSelectedService(
                                null
                              )
                            },
                          },
                        },
                        [_vm._v(" mdi-arrow-left ")]
                      )
                    : _vm._e(),
                  _c(
                    "h5",
                    {
                      staticClass: "my-0 ml-2",
                      staticStyle: { "font-family": "Rubik !important" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dataStorage.stepSettings.serviceSelected
                              .description !== "" &&
                              _vm.dataStorage.stepSettings.serviceCostStep === 1
                              ? _vm.dataStorage.stepSettings.serviceSelected
                                  .description
                              : _vm.$t("MechanicalRepairAndOtherServices")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              !_vm.dataStorage.stepSettings.loadingIndicator
                ? _c(
                    "v-row",
                    {
                      staticClass: "mt-2",
                      staticStyle: {
                        position: "relative",
                        background: "white",
                        width: "100%",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pr-0" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "prepend-inner-icon": "mdi-magnify",
                              placeholder: _vm.$t("SearchForYourService"),
                              label: _vm.$t("Search"),
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-window",
            {
              staticStyle: { overflow: "auto !important" },
              model: {
                value: _vm.dataStorage.stepSettings.serviceCostStep,
                callback: function ($$v) {
                  _vm.$set(_vm.dataStorage.stepSettings, "serviceCostStep", $$v)
                },
                expression: "dataStorage.stepSettings.serviceCostStep",
              },
            },
            [
              _c(
                "v-window-item",
                { attrs: { value: 0 } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0", staticStyle: { height: "500px" } },
                    [
                      _vm.dataStorage.stepSettings.loadingIndicator
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "text-center mt-10" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      size: 70,
                                      width: 5,
                                      color: "#12945f",
                                      indeterminate: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.dataStorage.stepSettings.loadingIndicator
                        ? _c(
                            "v-list",
                            { staticClass: "mt-3", attrs: { "two-line": "" } },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  model: {
                                    value: _vm.serviceSelected,
                                    callback: function ($$v) {
                                      _vm.serviceSelected = $$v
                                    },
                                    expression: "serviceSelected",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.dataStorage.stepSettings
                                      .servicesByCategorySelectedFiltered,
                                    function (service, index) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          {
                                            key: "mechanical-repair-" + index,
                                            on: {
                                              click: function ($event) {
                                                return _vm.displayEstimatedCostForSelectedService(
                                                  service
                                                )
                                              },
                                            },
                                          },
                                          [
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        service.description
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-icon",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    margin: "0",
                                                    "align-self": "center",
                                                  },
                                                },
                                                [
                                                  service.isSelected
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-check-circle "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                        index <
                                        _vm.dataStorage.stepSettings
                                          .servicesByCategorySelected.length -
                                          1
                                          ? _c("v-divider", {
                                              key:
                                                "mechanical-repair-divider-" +
                                                index,
                                            })
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-window-item",
                { attrs: { value: 1 } },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.dataStorage.stepSettings.loadingIndicator
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "text-center mt-10" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      size: 70,
                                      width: 5,
                                      color: "#12945f",
                                      indeterminate: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.dataStorage.stepSettings.loadingIndicator
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    color: "info",
                                    outlined: "",
                                    text: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("EstimateAmountIs")) +
                                      " "
                                  ),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          this.serviceCost.totalPromCost || 0
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    ". " +
                                      _vm._s(
                                        _vm.$t("FinalAmountDeterminationAlert")
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: { "font-size": "1rem" },
                                      attrs: {
                                        title: _vm.$t("TapToSeeInfo"),
                                        color: "info",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.displayServiceHelpDialog()
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-help-circle ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      rows: this.$vuetify.breakpoint.smAndDown
                                        ? 3
                                        : 5,
                                      outlined: "",
                                      "hide-details": "",
                                      name: "input-7-4",
                                      placeholder: _vm.$t(
                                        "AreThereAnyOtherDetail"
                                      ),
                                    },
                                    model: {
                                      value: _vm.serviceComment,
                                      callback: function ($$v) {
                                        _vm.serviceComment = $$v
                                      },
                                      expression: "serviceComment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _vm.dataStorage.stepSettings.serviceCostStep === 1
            ? _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { elevation: "0", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addService()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("AddService")) + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.serviceHelpDialog,
            callback: function ($$v) {
              _vm.serviceHelpDialog = $$v
            },
            expression: "serviceHelpDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "px-3 py-3 justify-space-between" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-bold mb-0",
                      staticStyle: { "font-size": "1rem" },
                    },
                    [_vm._v(_vm._s(_vm.$t("Information")))]
                  ),
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "black" },
                      on: {
                        click: function ($event) {
                          _vm.serviceHelpDialog = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pt-4 pb-1 px-3" }, [
                _c(
                  "p",
                  {
                    staticClass: "black--text",
                    staticStyle: { "font-size": "1rem" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("IncorrectServiceSelectedAlert"))
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("service-added-component", {
        attrs: { "service-added-dialog": _vm.serviceAddedDialog },
        on: {
          "next-step": _vm.nextStep,
          "add-more-services": _vm.closeNotificationDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }