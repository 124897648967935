var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "p-4",
      attrs: { width: "831", "max-width": "90%", persistent: "" },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "p-5" },
        [
          _c(
            "v-card-title",
            {
              staticClass: "pt-5",
              staticStyle: { "justify-content": "space-between" },
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "11" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "titleC mt-0 mb-0",
                        staticStyle: { "word-break": "break-word" },
                      },
                      [_vm._v(_vm._s(this.$t("DontSeeTheServicesYouNeed")))]
                    ),
                  ]),
                  _c("v-col", { attrs: { cols: "1" } }, [
                    _c("i", {
                      staticClass: "mdi mdi-close",
                      staticStyle: { float: "right", padding: "0px 10px" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelDialog()
                        },
                      },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-alert",
                { attrs: { color: "info", outlined: "", text: "" } },
                [
                  _vm._v(" " + _vm._s(this.$t("infoMissingService")) + ". "),
                  _c(
                    "v-icon",
                    {
                      staticStyle: { "font-size": "1rem" },
                      attrs: { title: _vm.$t("TapToSeeInfo"), color: "info" },
                      on: {
                        click: function ($event) {
                          return _vm.displayServiceHelpDialog()
                        },
                      },
                    },
                    [_vm._v(" mdi-help-circle ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      placeholder: this.$t("DescribeService"),
                      rows: "5",
                      outlined: "",
                      "hide-details": "",
                      "no-resize": "",
                      name: "input-7-4",
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticStyle: { "justify-content": "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { padding: "0px 40px" },
                      attrs: {
                        elevation: "0",
                        disabled: _vm.comment === "",
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.hideDialog()
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.serviceHelpDialog,
            callback: function ($$v) {
              _vm.serviceHelpDialog = $$v
            },
            expression: "serviceHelpDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "px-3 py-3 justify-space-between" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-bold mb-0",
                      staticStyle: { "font-size": "1rem" },
                    },
                    [_vm._v(_vm._s(_vm.$t("Information")))]
                  ),
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "black" },
                      on: {
                        click: function ($event) {
                          _vm.serviceHelpDialog = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pt-4 pb-1 px-3" }, [
                _c(
                  "p",
                  {
                    staticClass: "black--text",
                    staticStyle: { "font-size": "1rem" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("IncorrectServiceSelectedAlert"))
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }